<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Pengeluaran</b>
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/expenses/add')"
        v-if="btn"
      >Tambah</b-button>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>

            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-9">
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }"
                >
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }"
                >
                  Bulan Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('lastMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.lastMonth,
                    'btn-outline-info': btnActive.lastMonth == false,
                  }"
                >
                  Bulan Kemarin
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('year')"
                  v-bind:class="{
                    'btn-info': btnActive.year,
                    'btn-outline-info': btnActive.year == false,
                  }"
                >
                  Tahun Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  v-bind:class="{
                    'btn-info': btnActive.period,
                    'btn-outline-info': btnActive.period == false,
                  }"
                  @click="btnPeriodeOnClick"
                >
                  Periode
                </button>
              </div>
              <div class="col-md-3 col-12">
                <b-input-group>
                  <b-form-input
                    type="text"
                    v-model="filter.material_name"
                    placeholder="Saring Nama"
                  ></b-form-input>
                  <template #append>
                    <b-button squared @click="filterByPeriod" variant="success"
                      >Cari</b-button
                    >
                    <b-button squared @click="resetFilter" variant="danger"
                      >Reset</b-button
                    >
                  </template>
                </b-input-group>
              </div>
            </div>

            <!-- timeseries filter -->
            <div class="row mt-3">
              <div class="col-md-9" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button squared @click="filterByPeriod" variant="success"
                        >Cari</b-button
                      >
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button squared @click="filterByPeriod" variant="success"
                            >Cari</b-button
                          >
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive="sm"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>
              <template #cell(display_amount)='data'>
                {{data.item.display_amount}}  <i
                  class="fas fa-arrow-up"
                  style="color: red"
                  v-b-tooltip.hover
                  title="Stok Keluar"
                ></i>
              </template>
              <template #cell(display_deposit)='data'>
                {{data.item.display_deposit}} <i
                  class="fas fa-arrow-down"
                  style="color: green"
                  v-b-tooltip.hover
                  title="Stok Masuk"
                ></i>
              </template>

              <template #cell(actions)='data'>
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="$router.push({ path: '/expenses/detail/' + data.item.id })"
                ><i class="fas fa-eye px-0"></i></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="$router.push({ path: '/expenses/edit/' + data.item.id })"
                  v-if="btn"
                ><i class="fas fa-edit px-0"></i></b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.id)"
                  v-if="btn"
                ><i class="fas fa-trash px-0"></i></b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage" @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import moment from 'moment';

export default {

  name: "List",

  components: {
    Card
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
        start_date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "name",
          label: "Keterangan",
          sortable: true,
        },
        {
          key: "display_amount",
          label: "Pengeluaran (Rp)",
          sortable: true,
        },
        {
          key: "display_deposit",
          label: "Deposit (Rp)",
          sortable: true,
        },
        {
          key: "display_deposit_balance",
          label: "Sisa Deposit (Rp)",
          sortable: true,
        },
        {
          key: "created_by_name",
          label: "Penginput",
          sortable: true,
        },
        {
          key: "citizen_name",
          label: "Penerima BLT",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days : true,
        month : false,
        lastMonth : false,
        year : false,
        period : false,
      },
      // user access
      btn: true,
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let filterParams = `&material_name=${this.filter.material_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate('expenses', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      
      let a, b
      b = this.perPage * (this.currentPage - 1) + 1  
      for(a = 0; a < response.data.length; a++){
        response.data[a].number = b + a
      }

      this.items = response.data
    },

    filterByName() {
      this.pagination()
    },

    filterByPeriod() {
      this.filter.start_date = moment(this.filter.start_date).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.filter.end_date = moment(this.filter.end_date).endOf('day').format('YYYY-MM-DD HH:mm:ss')

      this.pagination()
    },

    // filter
    resetFilter() {
      this.filter.material_name = ''
      this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

      this.pagination()
    },

    btnPeriodeOnClick(){
      if(this.onShowPeriod == false){
        this.onShowPeriod = true
        this.btnActiveOnClick('period')
      }else{
        this.onShowPeriod = false
        this.btnActiveOnClick('period')
      }
    },

    btnActiveOnClick(type){
      if(type == 'days'){
        // set time
        this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = true
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'month'){
        // set time
        this.filter.start_date = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = true
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'lastMonth'){
        // set time
        this.filter.start_date = moment().subtract(1,'months').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().subtract(1,'months').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = true
        this.btnActive.year = false
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'year'){
        // set time
        this.filter.start_date = moment().startOf('year').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
        this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = true
        this.btnActive.period = false

        // hide period
        this.onShowPeriod = false
      }else if(type == 'period'){
        // set time
        this.filter.start_date = ""
        this.filter.end_date = ""
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = true
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete('expenses/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },
    
    setActiveButton(){
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for(a = 0; a < access_right.length; a++){
        if(access_right[a] == "1107"){
          this.btn = true
        }
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pengeluaran" }])
    // Get Data
    this.pagination()
    this.setActiveButton()
  },

}

</script>
